#home-div{
  /* background-image: url("../Images/HomePage.png"); */
  /* background-image: url("https://assets1.lottiefiles.com/packages/lf20_le8PpGpm9v.json"); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 80vw;
  height: 80vh;
  font-size: large;
}


#successLoaded{
  color: aquamarine;
}