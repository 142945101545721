#confetti-card{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20%;
}

#link {
  text-decoration: none;
  color: grey;
}